import { 
    all_patterns,
    starter_grades,
    red_colors,
    orange_colors,
    yellow_colors,
    green_colors,
    blue_colors,
    indigo_colors,
    violet_colors,
    pink_colors,
    brown_colors,
    greyscale_colors,
    pattern_types
} from "./squittles.ts";
//all_colors,
// squittles,
//import { combineReducers } from 'redux';
import { createStore } from 'redux';

const initialState = {
    allSquittles: [],
    storeSquittles: [],
};

const getRandomElement = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

const getRandomStatGrades = (array, number) => {
    const allStats = [];
    for(var i = 0; i< number;i++ ){
        const randomIndex = Math.floor(Math.random() * (array.length));
        allStats.push(array[randomIndex]);
    }
    return allStats;
}

const getRandomVariant = (selectedPattern) => {
    //need to search for pattern and get how many variants it has and then randomly pick a number 
    if (selectedPattern.variants == 0) return 0;
    const randomNumber = Math.floor(Math.random() * selectedPattern.variants)+1;
    return randomNumber;
}

// value is the stat value that determines how good you are at a stat, level goes to 100 and at a level increase is when value goes up
// depending on the grade the value goes up a certain range every level F has the lowest number increase range while SS has the highest
// need to create another variable that tracks how much till next level that determines how many more points till level up

const createRandomNewSquittle = (color) => {
    const base_color_info = getRandomElement(color);
    const All_grade_info = getRandomStatGrades(starter_grades,10);
    const pattern_color_info = getRandomElement(color);
    //const pattern_color_info2 = getRandomElement(all_colors);
    //const select_pattern_1 = getRandomElement(all_patterns);
    //const select_pattern_2 = getRandomElement(all_patterns);
    const newSquittle = {
        name:"",
        id: new Date().getTime(),
        user_id: "shop",
        is_alive: true,
        base_color: {
            hex: base_color_info.hex,
            color: base_color_info.color,
        },
        patterns:{
            secondary:{
                unlocked: true,
                // type:select_pattern_1.type,
                // variant: getRandomVariant(select_pattern_1),
                type: "none",
                variant: 0,
                color_info:{
                    ...pattern_color_info,
                }
            },
            tertiary:{
                unlocked: true,
                type:"none",
                variant: 0,
                color_info:{
                    ...pattern_color_info,
                }
            }
        },
        stats:{
            active: {
                speed:{
                    level: 0,
                    value: 0,
                    grade: All_grade_info[0],
                    toNext: 100,
                },
                swim:{
                    level: 0,
                    value: 0,
                    grade: All_grade_info[1],
                    toNext: 100,
                },
                strength:{
                    level: 0,
                    value: 0,
                    grade: All_grade_info[2],
                    toNext: 100,
                },
                stamina:{
                    level: 0,
                    value: 0,
                    grade: All_grade_info[3],
                    toNext: 100,
                },
                special:{
                    level: 0,
                    value: 0,
                    grade: All_grade_info[4],
                    toNext: 100,
                },
            },
            not_active:{
                speed: {
                    grade: All_grade_info[5],
                },
                swim:{
                    grade: All_grade_info[6],
                },
                strength:{
                    grade: All_grade_info[7],
                },
                stamina:{
                    grade: All_grade_info[8],
                },
                special:{
                    grade: All_grade_info[9],
                },
            },
        },
        consumed_or_passable: {
            colors:[
                ...color,

            ],
            patterns:[
                // select_pattern_1.type,
                "none",
            ]
        }
    };
    /*if (newSquittle.patterns.secondary.type !== "none") {
        newSquittle.patterns.secondary.color_info = {
          hex: pattern_color_info.hex,
          color: pattern_color_info.color,
        };
    };
    if (newSquittle.patterns.tertiary.type !== "none") {
        newSquittle.patterns.tertiary.color_info = {
          hex: pattern_color_info2.hex,
          color: pattern_color_info2.color,
        };
    };*/
    return newSquittle
};

const updateStat = (stat, experienceGained) => {
    if(stat.level == 100) return stat;
    const newStat = { ...stat }; // Create a copy of the input stat
    console.log ('toNext was: ',newStat.toNext);
    newStat.toNext -= experienceGained;
    console.log ('toNext is: ',newStat.toNext);

    while (newStat.toNext <= 0) {
      newStat.level += 1;
      const levelUpMultiplier = 1.5; // Adjust this as needed for level-up scaling
      console.log('added to toNext is ',Math.floor(100 * Math.pow(newStat.level + 1, levelUpMultiplier)));
      newStat.toNext += Math.floor(100 * Math.pow(newStat.level + 1, levelUpMultiplier));
  
      const randomNum = Math.floor(Math.random() * 11);
      let increase = 0;
  
      switch (stat.grade) {
        case 'SS':
          increase = 35;
          break;
        case 'S':
          increase = 30;
          break;
        case 'A':
          increase = 25;
          break;
        case 'B':
          increase = 20;
          break;
        case 'C':
          increase = 15;
          break;
        case 'D':
          increase = 10;
          break;
        case 'F':
          increase = 5;
          break;
      }
  
      newStat.value += increase + randomNum;
      if (newStat.level ==100) newStat.toNext = 1;
    }
  
    return newStat;
};
const getRandom = (list, count) =>{
    const items = [];
    for (let i = 0; i < count; i++) {
        items.push(getRandomElement(list));
    }
    return items;
};
const getNewOrder = (list) => {
    const shuffledList = [...list]; 
    for (let i = shuffledList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
    return shuffledList;
};

const getPatternObjects = (chosenPatternName) =>{
    // all_patterns is list with index.type matching pattern name 0 and randomly pick variant between 1 and index.variant and return it
    // existingColors.includes(colorInfo.color)
    const chosenVariants = [];
    
    for (let i = 0; i < all_patterns.length;i++){
        if(chosenPatternName[0] == 'none'){chosenVariants.push(0); break;}
        if (chosenPatternName[0].includes(all_patterns[i].type)){
            chosenVariants.push(getRandomVariant(all_patterns[i]));
            break;
        } 
    }
    for (let i = 0; i < all_patterns.length;i++){
        if(chosenPatternName[1] == 'none'){chosenVariants.push(0); break;}
        if (chosenPatternName[1].includes(all_patterns[i].type)){
            chosenVariants.push(getRandomVariant(all_patterns[i]));
        } 
    }
    return chosenVariants;
};

const randomlySelectedSquittle = (squittle1,squittle2) => {
    const randomNum = Math.random();
    if (randomNum < 0.5) {
        return squittle1;
    }
        return squittle2;
}

const combineTraitsOrColors = (object1,object2) => {
    return [...new Set(object1.concat(object2))];
}

const createNewSquittleThroughFuse = (squittle1,squittle2) =>{
    const selectedForPattern1 = randomlySelectedSquittle(squittle1,squittle2);
    const selectedForPattern2 = randomlySelectedSquittle(squittle1,squittle2);
    const allPossibleColors = combineTraitsOrColors(squittle1.consumed_or_passable.colors,squittle2.consumed_or_passable.colors);
    const allPossiblePatterns = combineTraitsOrColors(squittle1.consumed_or_passable.patterns,squittle2.consumed_or_passable.patterns);
    const newSquittle = {
        name:"",
        id: new Date().getTime(),
        user_id: squittle1.user_id,
        is_alive: true,
        base_color: {
            hex: randomlySelectedSquittle(squittle1,squittle2).base_color.hex,
            color: randomlySelectedSquittle(squittle1,squittle2).base_color.color,
        },
        patterns:{
            secondary:{
                unlocked: true,
                type: selectedForPattern1.patterns.secondary.type,
                variant: selectedForPattern1.patterns.secondary.variant,
                color_info:{
                    ...randomlySelectedSquittle(squittle1,squittle2).patterns.secondary.color_info,
                }
            },
            tertiary:{
                unlocked: true,
                type: selectedForPattern2.patterns.tertiary.type,
                variant: selectedForPattern2.patterns.tertiary.variant,
                color_info:{
                    ...randomlySelectedSquittle(squittle1,squittle2).patterns.tertiary.color_info,
                }
            }
        },
        stats:{
            active: {
                speed:{
                    level: 0,
                    value: 0,
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.active.speed.grade,
                    toNext: 100,
                },
                swim:{
                    level: 0,
                    value: 0,
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.active.swim.grade,
                    toNext: 100,
                },
                strength:{
                    level: 0,
                    value: 0,
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.active.strength.grade,
                    toNext: 100,
                },
                stamina:{
                    level: 0,
                    value: 0,
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.active.stamina.grade,
                    toNext: 100,
                },
                special:{
                    level: 0,
                    value: 0,
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.active.special.grade,
                    toNext: 100,
                },
            },
            not_active:{
                speed: {
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.not_active.speed.grade,
                },
                swim:{
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.not_active.swim.grade,
                },
                strength:{
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.not_active.strength.grade,
                },
                stamina:{
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.not_active.stamina.grade,
                },
                special:{
                    grade: randomlySelectedSquittle(squittle1,squittle2).stats.not_active.special.grade,
                },
            },
        },
        consumed_or_passable: {
            colors:[
                ...allPossibleColors,

            ],
            patterns:[
                ...allPossiblePatterns,
            ]
        }
    };
    return newSquittle;
}

const createNewSquittlesThroughPop = (squittle) =>{
    const allPossibleColors = squittle.consumed_or_passable.colors;
    const allPossiblePatterns = squittle.consumed_or_passable.patterns;
    const allPossibleSpeedStats = [squittle.stats.active.speed.grade,squittle.stats.not_active.speed.grade];
    const allPossibleSwimStats = [squittle.stats.active.swim.grade,squittle.stats.not_active.swim.grade];
    const allPossibleStrengthStats = [squittle.stats.active.strength.grade,squittle.stats.not_active.strength.grade];
    const allPossibleStaminaStats = [squittle.stats.active.stamina.grade,squittle.stats.not_active.stamina.grade];
    const allPossibleSpecialStats = [squittle.stats.active.special.grade,squittle.stats.not_active.special.grade];
    const numOfSquittlestoCreate = Math.floor(Math.random() * 2)+2;
    const returningSquittles = [];

    for(let i = 0; i<numOfSquittlestoCreate; i++){ //Only want to keep special colors and chosen color ranges
        const chosenColors = getRandom(allPossibleColors, 3);
        const chosenPatterns = getRandom(allPossiblePatterns,2);
        const chosenPatternObjects = getPatternObjects(chosenPatterns);

        const All_grade_info = [
            ...getNewOrder(allPossibleSpeedStats), 
            ...getNewOrder(allPossibleSwimStats),
            ...getNewOrder(allPossibleStrengthStats),
            ...getNewOrder(allPossibleStaminaStats),
            ...getNewOrder(allPossibleSpecialStats)
        ];

        const newSquittle = {
            name:"",
            id: `${new Date().getTime()}-${i}`,
            user_id: squittle.user_id,
            is_alive: true,
            base_color: {
                hex: chosenColors[0].hex,
                color: chosenColors[0].color,
            },
            patterns:{
                secondary:{
                    unlocked: true,
                    type: chosenPatterns[0],
                    variant: chosenPatternObjects[0],
                    color_info:{
                        ...chosenColors[1],
                    }
                },
                tertiary:{
                    unlocked: true,
                    type: chosenPatterns[1],
                    variant: chosenPatternObjects[1],
                    color_info:{
                        ...chosenColors[2],
                    }
                }
            },
            stats:{
                active: {
                    speed:{
                        level: 0,
                        value: 0,
                        grade: All_grade_info[0],
                        toNext: 100,
                    },
                    swim:{
                        level: 0,
                        value: 0,
                        grade: All_grade_info[2],
                        toNext: 100,
                    },
                    strength:{
                        level: 0,
                        value: 0,
                        grade: All_grade_info[4],
                        toNext: 100,
                    },
                    stamina:{
                        level: 0,
                        value: 0,
                        grade: All_grade_info[6],
                        toNext: 100,
                    },
                    special:{
                        level: 0,
                        value: 0,
                        grade: All_grade_info[8],
                        toNext: 100,
                    },
                },
                not_active:{
                    speed: {
                        grade: All_grade_info[1],
                    },
                    swim:{
                        grade: All_grade_info[3],
                    },
                    strength:{
                        grade: All_grade_info[5],
                    },
                    stamina:{
                        grade: All_grade_info[7],
                    },
                    special:{
                        grade: All_grade_info[9],
                    },
                },
            },
            consumed_or_passable: {
                colors:[
                    ...allPossibleColors,

                ],
                patterns:[
                    ...allPossiblePatterns,
                ]
            }
        };
        returningSquittles.push(newSquittle);
    }
    return returningSquittles;
};

const feedSquittleFruit = (squittle,type, fruit) =>{
    if(type == "status"){
        switch(fruit.name){
            case 'speed':
                squittle.stats.active.speed = updateStat(squittle.stats.active.speed, fruit.exp);
                break;
            case 'swim':
                squittle.stats.active.swim = updateStat(squittle.stats.active.swim, fruit.exp);
                break;
            case 'strength':
                squittle.stats.active.strength = updateStat(squittle.stats.active.strength, fruit.exp);
                break;
            case 'stamina':
                squittle.stats.active.stamina = updateStat(squittle.stats.active.stamina, fruit.exp);
                break;
            case 'special':
                squittle.stats.active.special = updateStat(squittle.stats.active.special, fruit.exp);
                break;
        }
    } 
    else if (type == "color"){
        // go through fruit and add any colors not already specified to consumed_or_passable.colors
        const existingColors = squittle.consumed_or_passable.colors.map((colorInfo) => colorInfo.color);
        const colors = fruit.colors;
        
        const newColors = colors.filter((colorInfo) => !existingColors.includes(colorInfo.color));
        squittle.consumed_or_passable.colors.push(...newColors);
    } 
    else if (type == "pattern"){
        if (!squittle.consumed_or_passable.patterns.includes(fruit) && pattern_types.includes(fruit)){
            squittle.consumed_or_passable.patterns = [
                ...squittle.consumed_or_passable.patterns,
                fruit,
            ]
        }
    }
    return squittle;
};

const transfer_ownership = (squittle, username) => {
    const updated_squittle = {...squittle};
    updated_squittle.user_id = username;
    return updated_squittle;
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SQUITTLE': {
            const updated_squittle = transfer_ownership(action.payload.squittle, action.payload.username);
            const addSquittleNewState = {
                ...state,
                allSquittles: [...state.allSquittles, updated_squittle],
                storeSquittles: [...state.storeSquittles],
            };
            return addSquittleNewState;
        }
        case 'ADD_RANDOM_STORE': {
            return {
                ...state,
                storeSquittles: [
                    createRandomNewSquittle(red_colors), createRandomNewSquittle(orange_colors),
                    createRandomNewSquittle(yellow_colors), createRandomNewSquittle(green_colors),
                    createRandomNewSquittle(blue_colors), createRandomNewSquittle(indigo_colors),
                    createRandomNewSquittle(violet_colors), createRandomNewSquittle(pink_colors),
                    createRandomNewSquittle(brown_colors), createRandomNewSquittle(greyscale_colors)
                ]
            };
        }
        case 'ADD_SQUITTLE_NAME':
        case 'UPDATE_SQUITTLE_CONSUMED':
        case 'UPDATE_SQUITTLE_STATS': {
            const squittleToUpdateIndex = state.allSquittles.findIndex(
                squittle => squittle.id === action.payload.squittle.id
            );
            const updatedAllSquittles = [...state.allSquittles];

            switch (action.type) {
                case 'ADD_SQUITTLE_NAME': {
                    const squittleWithUpdatedName = {
                        ...state.allSquittles[squittleToUpdateIndex],
                        name: action.payload.name,
                    };
                    updatedAllSquittles[squittleToUpdateIndex] = squittleWithUpdatedName;
                    break;
                }
                case 'UPDATE_SQUITTLE_CONSUMED': {
                    const squittleToUpdate = { ...state.allSquittles[squittleToUpdateIndex] };
                    const updatedSquittle = feedSquittleFruit(
                        squittleToUpdate,
                        action.payload.type,
                        action.payload.fruit
                    );
                    updatedAllSquittles[squittleToUpdateIndex] = updatedSquittle;
                    break;
                }
                case 'UPDATE_SQUITTLE_STATS': {
                    const squittleToUpdate = { ...state.allSquittles[squittleToUpdateIndex] };
                    const updatedSquittle = feedSquittleFruit(
                        squittleToUpdate,
                        action.payload.type,
                        action.payload.fruit
                    );
                    updatedAllSquittles[squittleToUpdateIndex] = updatedSquittle;
                    break;
                }
            }

            const updatedSquittleNewState = {
                ...state,
                allSquittles: updatedAllSquittles,
            };
            return updatedSquittleNewState;
        }
        case 'POP_SQUITTLE': {
            const squittleToPopIndex = state.allSquittles.findIndex(
                squittle => squittle.id === action.payload.squittle.id
            );

            if (squittleToPopIndex !== -1) {
                const newStateWithPopped = [...state.allSquittles];
                newStateWithPopped[squittleToPopIndex].is_alive = false;

                const newPoppedSquittles = createNewSquittlesThroughPop(action.payload.squittle);
                newPoppedSquittles.forEach(squittle => {
                    newStateWithPopped.push(squittle);
                });

                return {
                    ...state,
                    allSquittles: newStateWithPopped
                };
            }

            return state;
        }
        case 'FUSE_SQUITTLE': {
            const squittle1Id = action.payload.squittle[0].id;
            const squittle2Id = action.payload.squittle[1].id;

            const newStateWithFused = state.allSquittles.map(squittle => {
                if (squittle.id === squittle1Id || squittle.id === squittle2Id) {
                    return {
                        ...squittle,
                        is_alive: false,
                    };
                }
                return squittle;
            });

            const newFusedSquittle = createNewSquittleThroughFuse(action.payload.squittle[0], action.payload.squittle[1]);
            newStateWithFused.push(newFusedSquittle);

            return {
                ...state,
                allSquittles: newStateWithFused
            };
        }
        case 'GET_SQUITTLES':
            return state.allSquittles;
        case 'GET_USERS_SQUITTLES':
            return state.allSquittles;
        default:
            return state;
    }
};


export const addRandomStoreSquittle = () => {
    return {
        type: 'ADD_RANDOM_STORE',
    };
};
export const chooseStoreSquittle = (squittle, username) => {
    return {
        type: 'ADD_SQUITTLE',
        payload: {squittle:squittle,username:username},
    };
};
export const popSquittle = (squittle) => {
    return {
        type: 'POP_SQUITTLE',
        payload: {squittle:squittle},
    };
}

export const fuseSquittles = (squittle) => {
    console.log('something is wrong getting the squittles, squittle bundle should be 2 squittles: ',squittle);
    return {
        type: 'FUSE_SQUITTLE',
        payload: {squittle:squittle},
    };
}

export const changeSquittleName = (squittle, newname) => {
    return {
        type: 'ADD_SQUITTLE_NAME',
        payload: {squittle:squittle, name:newname}
    }
};
export const updateSquittleStatsByFruit = (squittle, fruit) =>{
    return {
        type: 'UPDATE_SQUITTLE_STATS',
        payload: {squittle:squittle, type:'status', fruit:fruit}
    }
};
export const updateSquittleConsumedByFruit = (squittle, type, fruit) =>{
    return {
        type: 'UPDATE_SQUITTLE_CONSUMED',
        payload: {squittle:squittle, type:type, fruit:fruit}
    };
};

const store = createStore(rootReducer);

export default store;