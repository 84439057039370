import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --space-1: 8px;
    --space-2: 16px;
    --space-3: 24px;
    --space-4: 32px;
    --font-disclaimer: 12px;
    --font-body-sm: 14px;
    --font-body-md: 16px;
    --font-body-lg: 18px;
    --font-subheading-sm: 20px;
    --font-subheading-md: 24px;
    --font-heading-sm: 32px;
    --font-heading-md: 36px;
    --font-heading-lg: 48px;
    --font-hero: 64px;
    --color-lightBrown: #e4d9c4;
    --color-brown: #d6c8b8;
    --color-darkBrown:#69615A;
    --color-black: #222222;
    --color-white: #ffffff;
    --family-my: Myfontnew;
    --family-default: Helvetica;
  }
`;

const mapSpaceToCustomProperty = space => {
  const spaceMappings = {
    1: 'var(--space-1)',
    2: 'var(--space-2)',
    3: 'var(--space-3)',
    4: 'var(--space-4)',
  };

  return spaceMappings[space];
};

export const Space = styled.div`
  margin-bottom: ${props => mapSpaceToCustomProperty(props.mb)};
`;

const mapFontSizeToCustomProperty = fontSize => {
  const fontSizeMappings = {
    d: 'var(--font-disclaimer)',
    bs: 'var(--font-body-sm)',
    bm: 'var(--font-body-md)',
    bl: 'var(--font-body-lg)',
    shs: 'var(--font-subheading-sm)',
    shm: 'var(--font-subheading-md)',
    hs: 'var(--font-heading-sm)',
    hm: 'var(--font-heading-md)',
    hl: 'var(--font-heading-lg)',
    h: 'var(--font-hero)',

  };

  return fontSizeMappings[fontSize];
};

const mapColorToCustomProperty = color => {
  const colorMappings = {
    lightBrown: 'var(--color-lightBrown)',
    brown: 'var(--color-brown)',
    darkBrown: 'var(--color-darkBrown)',
    black: 'var(--color-black)',
    white: 'var(--color-white)',
  };

  return colorMappings[color];
};

export const Text = styled.div`
  font-family: helvetica;
  font-size: ${({ size = 'bl' }) => mapFontSizeToCustomProperty(size)};
  color: ${({ color = 'black' }) => mapColorToCustomProperty(color)};
`;

export const Input = styled.input`
    width: 100%;
    max-width: 400px;
    font-size: 14px;
    padding: 8px;
    margin: 0 0 8px 0;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
`;
/*
const Button = styled.button`
  padding: 8px 0;
  width: 100%;
  font-weight: bold;
  font-size:18px;
  color: white;
  background-color: #6699FF;
  border-color:#6699FF;
  border-radius: 8px;
  cursor: pointer;
`;*/


export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.col}, 1fr);
    margin: auto;
`;

export const GridItem = styled.div`
  ${props => props.gridCol && `grid-column: span ${props.gridCol};`}
`;