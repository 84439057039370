
export const afterSigninSuccessful = (token) => {
  console.log(' I am a token', token)
  localStorage.setItem('squittles-token', token); // store the token in local storage
};

export const logOut = () => {
  localStorage.removeItem('squittles-token'); 
};




