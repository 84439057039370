export const allRoutes = {
    '/': {
      component: 'HomePage',
      props: {
        showNav: false,
        showFooter: true,
      },
    },
    '/auth': {
      component: 'AuthPage',
      props: {
        showNav: false,
        showFooter: true,
      },
    },
    '/welcome': {
      component: 'WelcomePage',
      props: {
        showNav: false,
        showFooter: true,
      },
    },
    '/user': {
      component: 'UserPage',
      props: {
        showNav: false,
        showFooter: true,
        isPrivate: true,
      },
    },
    // Add more routes as needed
  };