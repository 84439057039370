type hexColor = `#${string}`;

type color_info_props = {
    color: string,
    hex: hexColor,
};
export const pink_colors: color_info_props[] = [
    {
        color: "Mulberry",
        hex: "#8A1554" 
    },
    {
        color: "Light pink",
        hex: "#FFA6C9"
    },
    {
        color: "Hot pink",
        hex: "#FF69B4"
    },
    {
        color: "Deep pink",
        hex: "#FF1493"
    },
];
export const red_colors: color_info_props[] = [
    {
        color: "Crimson",
        hex: "#DC143C",
    },
    {
        color: "Firebrick",
        hex: "#9F1E1E"
    },
    {
        color: "Carnation",
        hex: "#F46248"
    }
];
export const brown_colors: color_info_props[] = [
    {
        color: "Brown",
        hex: "#673F22",
    },
    {
        color: "Dark chocolate",
        hex:"#492913",
    },
    {
        color: "Coffee",
        hex: "#865532",
    },
    {
        color:"Sandal",
        hex:"#9D7253",
    }
];
export const orange_colors: color_info_props[] = [
    {
        color: "Orange",
        hex: "#FFA500"
    },
    {
        color: "Zest",
        hex: "#E6731E"
    },
    {
        color: "Peach",
        hex: "#FBBC84"
    }
];
export const yellow_colors: color_info_props[] = [
    {
        color: "Goldenrod",
        hex: "#DAA520",
    },
    {
        color: "Lemon",
        hex: "#EBE40F",
    },
    {
        color: "butter",
        hex:"#FFFC79",
    },
];
export const green_colors: color_info_props[] = [
    {
        color: "Radioactive",
        hex: "#2CFA1F"
    },
    {
        color: "Forest Green",
        hex: "#228B22",
    },
    {
        color: "Lime Green",
        hex:"#74E411",
    },
    {
        color: "Shamrock",
        hex:"#33CC99",
    },
    {
        color:"Parsley",
        hex:"#134F19",
    },
    {
        color:"Mint",
        hex: "#98FF98",
    }
];
export const blue_colors: color_info_props[] = [
    {
        color: "Blueberry",
        hex: "#4f86f7"
    },
    {
        color:"Sky blue",
        hex:"#70E9EE",
    },
    {
        color:"Foam",
        hex:"#D5F3FC",
    },
    {
        color:"Denim",
        hex:"#315F96",
    }
];
export const indigo_colors: color_info_props[] = [
    {
        color: "Indigo",
        hex: "#4B0082"
    },
    {
        color: "Deep Indigo",
        hex: "#2E0854"
    },
    {
        color: "Electric Indigo",
        hex: "#6F00FF"
    }
];
export const violet_colors: color_info_props[] = [
    {
        color: "Lavender",
        hex: "#B77CDF"
    },
    {
        color: "Lavender mist",
        hex: "#E6B8F9"
    },
    {
        color: "Purple",
        hex: "#800080"
    },
    {
        color: "Violet",
        hex: "#8A2BE2"
    },
    {
        color:"Grape",
        hex:"#492163"
    },
];
export const greyscale_colors: color_info_props[] = [
    {
        color: "Black",
        hex: "#2E2F27"
    },
    {
        color: "Gravel",
        hex: "#4A444B",
    },
    {
        color: "Smoke",
        hex: "#798682"
    },
    {
        color: "Silver",
        hex: "#C0C0C0"
    },
    {
        color: "Snow",
        hex: "#F7FAF7",
    }
];
export const all_colors: color_info_props[] = [
    ...red_colors,
    ...orange_colors,
    ...yellow_colors,
    ...green_colors,
    ...blue_colors,
    ...indigo_colors,
    ...violet_colors,
    ...pink_colors,
    ...brown_colors,
    ...greyscale_colors,
    
];
type pattern_type_props = "none" | "ombre" | "splot" | "spots" | "stripes" | "skele" | "half" | "turtle";
export const pattern_types: pattern_type_props[] = [
    'ombre', 'spots', 'stripes', 'half', 'splot', 'turtle', 'skele' 
];
type pattern_option_props = {
    type: pattern_type_props,
    variants: number,
};

type pattern_props = {
    unlocked: boolean,
    type?: pattern_type_props,
    variant?: number,
    color_info?: color_info_props,
};
export const ombre_pattern: pattern_option_props = {
    type: "ombre",
    variants: 6,
};
export const spots_pattern: pattern_option_props = {
    type: "spots",
    variants: 5,
};
export const stripes_pattern: pattern_option_props = {
    type: "stripes",
    variants: 4,
};
export const half_pattern: pattern_option_props = {
    type: "half",
    variants: 3,
};
export const splot_pattern: pattern_option_props = {
    type: "splot",
    variants: 2,
};

export const turtle_pattern: pattern_option_props = {
    type: "turtle",
    variants: 1,
};
export const skele_pattern: pattern_option_props = {
    type: "skele",
    variants: 1,
};

export const starting_patterns: pattern_option_props[] =[
    ombre_pattern,
    stripes_pattern,
    spots_pattern,
];

export const all_patterns: pattern_option_props[] = [
    ombre_pattern,
    stripes_pattern,
    spots_pattern,
    half_pattern,
    splot_pattern,
    turtle_pattern,
    skele_pattern,
];

type grade_props = "SS" | "S" | "A" | "B" | "C"| "D"| "F";
export const starter_grades: grade_props[] = ["A","B","C","D","F"];
type level_props = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 
                   11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 
                   21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 
                   31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 
                   41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 
                   51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 | 60 | 
                   61 | 62 | 63 | 64 | 65 | 66 | 67 | 68 | 69 | 70 | 
                   71 | 72 | 73 | 74 | 75 | 76 | 77 | 78 | 79 | 80 | 
                   81 | 82 | 83 | 84 | 85 | 86 | 87 | 88 | 89 | 90 | 
                   91 | 92 | 93 | 94 | 95 | 96 | 97 | 98 | 99 | 100;

type stats_prop = {
    level: level_props,
    value: number,
    grade: grade_props,
    toNext: number,
};
type squittle_props = {
    name?: string,
    id: string,
    user_id: string,
    is_alive: boolean,
    base_color: color_info_props,
    patterns: {
        secondary: pattern_props,
        tertiary: pattern_props,
    },
    stats:{
        active: {
            speed:stats_prop,
            swim:stats_prop,
            strength:stats_prop,
            stamina:stats_prop,
            special:stats_prop,
        },
        not_active:{
            speed: {
                grade:grade_props,
            },
            swim: {
                grade:grade_props,
            },
            strength: {
                grade:grade_props,
            },
            stamina: {
                grade:grade_props,
            },
            special: {
                grade:grade_props,
            },
        }
    },
    consumed_or_passable:{
        colors:color_info_props[],
        patterns?: pattern_type_props[],
        stats?: any,
    }
}

export const squishy_final: squittle_props = {
    name:"Squishy",
    id: "unique_id",
    user_id: "user_id",
    is_alive: true,
    base_color: {
        color: "Light pink",
        hex: "#FFA6C9"
    },
    patterns: {
        secondary: {
            unlocked: true,
            type:"ombre",
            variant: 3,
            color_info: {
                color:"Sky blue",
                hex:"#70E9EE",
            },
        },
        tertiary: {
            unlocked: true,
            type: "ombre",
            variant: 1,
            color_info: {
                color: "butter",
                hex:"#FFFC79",
            },
        },
    },
    stats: {
        active:{
            speed:{
                level: 0,
                value: 0,
                grade: "B",
                toNext: 100,
            },
            swim:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            strength:{
                level: 0,
                value: 0,
                grade: "D",
                toNext: 100,
            },
            stamina: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            special: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            }
        },
        not_active:{
            speed:{
                grade: "C",
            },
            swim:{
                grade:"B"
            },
            strength:{
                grade:"F",
            },
            stamina: {
                grade: "C",
            },
            special: {
                grade: "A",
            }
        },
    },
    consumed_or_passable: {
        colors:[
            ...pink_colors,
            ...yellow_colors,
            ...blue_colors,
        ],
        patterns:[
            "ombre","none"
        ],
    }
};

export const mint_final: squittle_props = {
    name:"Yolk",
    id: "unique_id_mint",
    user_id: "user_id",
    is_alive: true,
    base_color: {
        color: "Snow",
        hex: "#F7FAF7",
    },
    patterns: {
        secondary: {
            unlocked: true,
            type:"none",
            variant: 0,
        },
        tertiary: {
            unlocked: true,
            type:"spots",
            variant: 3,
            color_info: {
                color: "Lemon",
                hex: "#EBE40F",
            },
        },
    },
    stats: {
        active:{
            speed:{
                level: 0,
                value: 0,
                grade: "B",
                toNext: 100,
            },
            swim:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            strength:{
                level: 0,
                value: 0,
                grade: "D",
                toNext: 100,
            },
            stamina: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            special: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            }
        },
        not_active:{
            speed:{
                grade: "C",
            },
            swim:{
                grade:"B"
            },
            strength:{
                grade:"F",
            },
            stamina: {
                grade: "C",
            },
            special: {
                grade: "A",
            }
        },
    },
    consumed_or_passable: {
        colors:[
            ...green_colors,
            ...brown_colors,
        ],
        patterns:[
            "none","spots",
        ],
    }
};

export const corn_final: squittle_props = {
    name:"Corn",
    id: "unique_id_corn",
    user_id: "user_id",
    is_alive: true,
    base_color: {
        color: "Lemon",
        hex: "#EBE40F",
    },
    patterns: {
        secondary: {
            unlocked: true,
            type:"half",
            variant: 3,
            color_info: {
                color: "Forest Green",
                hex: "#228B22",
            },
        },
        tertiary: {
            unlocked: true,
            type:"spots",
            variant: 5,
            color_info: {
                color: "Goldenrod",
                hex: "#DAA520",
            },
        },
    },
    stats: {
        active:{
            speed:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            swim:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            strength:{
                level: 0,
                value: 0,
                grade: "A",
                toNext: 100,
            },
            stamina: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            special: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            }
        },
        not_active:{
            speed:{
                grade: "C",
            },
            swim:{
                grade:"B"
            },
            strength:{
                grade:"C",
            },
            stamina: {
                grade: "C",
            },
            special: {
                grade: "A",
            }
        },
    },
    consumed_or_passable: {
        colors:[
            ...green_colors,
            ...yellow_colors,
        ],
        patterns:[
            "none","spots","half"
        ],
    }
};

export const bwr_final: squittle_props = {
    name:"Memptus Peptu",
    id: "unique_id_Pepps",
    user_id: "user_id",
    is_alive: true,
    base_color: {
        color: "Crimson",
        hex: "#DC143C",
        
    },
    patterns: {
        secondary: {
            unlocked: true,
            type:"half",
            variant: 2,
            color_info: {
                color: "Gravel",
                hex: "#4A444B",
            },
        },
        tertiary: {
            unlocked: true,
            type:"spots",
            variant: 5,
            color_info: {
                color: "Gravel",
                hex: "#4A444B",
            },
        },
    },
    stats: {
        active:{
            speed:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            swim:{
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            strength:{
                level: 0,
                value: 0,
                grade: "A",
                toNext: 100,
            },
            stamina: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            },
            special: {
                level: 0,
                value: 0,
                grade: "C",
                toNext: 100,
            }
        },
        not_active:{
            speed:{
                grade: "C",
            },
            swim:{
                grade:"B"
            },
            strength:{
                grade:"C",
            },
            stamina: {
                grade: "C",
            },
            special: {
                grade: "A",
            }
        },
    },
    consumed_or_passable: {
        colors:[
            ...greyscale_colors,
            ...red_colors,
        ],
        patterns:[
            "none","ombre","half"
        ],
    }
};

export const squittles = [
    
];
export const all_fruits = [
    {
        name: 'red-fruit',
        unlocks: red_colors,
    },
    {
        name: 'orange-fruit',
        unlocks: orange_colors,
    },
    {
        name: 'yellow-fruit',
        unlocks: yellow_colors,
    },
    {
        name: 'green-fruit',
        unlocks: green_colors,
    },
    {
        name: 'blue-fruit',
        unlocks: blue_colors,
    },
    {
        name: 'indigo-fruit',
        unlocks: indigo_colors,
    },
    {
        name: 'violet-fruit',
        unlocks: violet_colors,
    },
    {
        name: 'pink-fruit',
        unlocks: pink_colors,
    },
    {
        name: 'brown-fruit',
        unlocks: brown_colors,
    },
    {
        name: 'grey-fruit',
        unlocks: greyscale_colors,
    },
    {
        name: 'ombre-fruit',
        unlocks: ombre_pattern,
    },
    {
        name: 'spot-fruit',
        unlocks: spots_pattern,
    },
    {
        name: 'stripe-fruit',
        unlocks: stripes_pattern,
    },
    {
        name: 'splot-fruit',
        unlocks: splot_pattern,
    },
    {
        name: 'half-fruit',
        unlocks: half_pattern,
    },
];

export const squittle_final = {
    name:"Yolk",
    id: "unique_id",
    user_id: "user_id",
    base_color: {
        color: "white",
        hex: "#EBEEEE",
    },
    patterns: {
        secondary: {
            type:"ombre",
            variant: 3,
            color_info: {
                color: "banana",
                hex:"#fffc79",
            },
        },
        tertiary: {
            type: "none",
            variant: 0,
        },
    },
    stats: {
        active:{
            speed:{
                level: 0,
                stat: 0,
                grade: "B",
            },
            swim:{
                level: 0,
                stat: 0,
                grade: "C",
            },
            strength:{
                level: 0,
                stat: 0,
                grade: "D",
            },
            stamina: {
                level: 0,
                stat: 0,
                grade: "C",
            },
            special: {
                level: 0,
                stat: 0,
                grade: "C",
            }
        },
        hidden:{
            speed:{
                grade: "C",
            },
            swim:{
                grade:"B"
            },
            strength:{
                grade:"F",
            },
            stamina: {
                grade: "C",
            },
            special: {
                grade: "A",
            }
        },
    },
    consumed_or_passable: {
        colors:[
            {
                color: "yellow",
                hex:"#F1F068",
            },
            {
                color: "pink",
                hex: "#FFC3E1",
            },
            {
                color: "blue",
                hex:"#70E9EE",
            },
        ],
        patterns:[
            {
                type: "ombre",
            },
        ],
    }
};
