import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { allRoutes } from './routeConfig';
import store from './reducers/index';
import Nav from './partials/navbar';
import Banner from './partials/banner';
import TopNavBG from './partials/topNavbarBackground';
import PrivateRoute from './partials/functions/PrivateRoute';
import { GlobalStyle } from './partials/comonComponents';

function App() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <Routes>
          {Object.keys(allRoutes).map((path) => {
            const route = allRoutes[path];
            const Component = lazy(() => import(`./pages/${route.component}`)); // Use lazy import

            const RouteElement = route.props?.isPrivate ? (
              <PrivateRoute element={<Component />} />
            ) : (
              <Component />
            );

            return (
              <Route
                key={path}
                path={path}
                element={
                  <React.Fragment>
                    {route.props?.showNav && (
                      <TopNavBG>
                        <Banner />
                        <Nav />
                      </TopNavBG>
                    )}
                    <Suspense fallback={<div>Loading...</div>}>{RouteElement}</Suspense>
                    {/*route.props?.showFooter && <div>Footer here</div>*/}
                  </React.Fragment>
                }
              />
            );
          })}
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
