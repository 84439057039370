import React from 'react';
import styled, { css } from 'styled-components';
import './tempNav.css';
import { logOut } from './functions/auth';
import { useNavigate } from 'react-router-dom';

const noSelect = () => css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

const NavWrapper = styled.div`
  max-width: 1200px;
  width:100%;
  margin: 0px auto;
  padding: 32px 0px;
`;
const NavUList = styled.ul`
  list-style-type: none;
  display:table;
  margin: auto;
  align: center;
  padding: 0;
`;

const NavList = styled.li`
  font-size: 24px;
  font-family: Myfontnew;
  display: inline-block;
  padding: 16px 30px;
  white-space:nowrap;
  background-color: #d6c8b8;
  color: #69615a;
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ isFirst }) =>
    isFirst &&
    `
    border-radius: 10px 0 0 0;
  `}

  ${({ isLast }) =>
    isLast &&
    `
    border-radius: 0 10px 0 0;
  `}
  &:hover {
    background-color: #e4d9c4;
  }
`;

const ListEndLeft = styled(NavList)`
  background-color: #aba095;
  color: #aba095;
  margin-top: 10px;
  border-radius: 0 0 10px 0;
  padding: 6px 30px 26px 30px;
  ${noSelect}
`;
const ListEndRight = styled(NavList)`
  background-color: #aba095;
  color: #aba095;
  margin-top: 10px;
  border-radius: 0 0 0 10px;
  padding: 6px 30px 26px 30px;
  ${noSelect}
`;

const SIGNED_IN_DISPLAY = [
  {
    name: "Home",
    link: '/'
  },
  {
    name: "News",
    link: "/News"
  },
  {
    name: "Explore",
    link: "/Welcome"
  },
  {
    name: "Games",
    link: "/"
  },
  {
    name: "FAQ",
    link: "/"
  },
  {
    name: "Logout",
    link: "/",
    onClick: () => {
      logOut();
    }
  },
];



const Nav = ( {display = SIGNED_IN_DISPLAY} ) => {
  const navigate = useNavigate();

  return (
    <NavWrapper>
      <NavUList>
        <ListEndLeft>.</ListEndLeft>
        {display.map((item, index) => (
          <NavList key={index} isFirst={index === 0} isLast={index === display.length - 1} onClick={() => {
            if (item.link) { navigate(item.link);}
            if (item.onClick) { item.onClick(); }
          }}>
              {item.name}
          </NavList>
        ))}
        <ListEndRight>.</ListEndRight>
      </NavUList>
      
    </NavWrapper>
  );
  }
  
  export default Nav;
  // reference https://www.kriiks.com/
