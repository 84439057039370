import React from 'react';
import styled from 'styled-components';
import './tempNav.css';

const BannerWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    padding: 32px 0 0 0;
`;
const SquittlesLogo = styled.div`
    margin: 0 auto;
    text-align: center;
    font-size: 100px;
    font-family: MyfontNew;
    color: #8abfff;
`;

function Banner() {
    return (
      <BannerWrapper>
        <SquittlesLogo>
            Squittles
        </SquittlesLogo>
    </BannerWrapper>
    );
  }
  
  export default Banner;