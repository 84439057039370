import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem('squittles-token');
  let isAuthenticated = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      isAuthenticated = !!decodedToken; // Check if token is valid
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/auth" />;
};

export default PrivateRoute;
